@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "ppeiko";
    src: local("ppeiko-thin"), url("./fonts/ppeiko-thin.otf") format("opentype");
    font-weight: normal;
    font-display: swap;
}

* {
    font-family: "ppeiko";
}
