.hide-scrollbar::-webkit-scrollbar {
    display: none;
}

.custom-scrollbar::-webkit-scrollbar {
    height: 0.45em;
    cursor: pointer;
}

.custom-scrollbar::-webkit-scrollbar-track {
    border-radius: 100vw;
    margin-top: 10rem;
}

.work__filters {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 0.75rem;
    margin-bottom: var(--mb-2);
}

.work__item {
    cursor: pointer;
    color: var(--title-color);
    padding: 0.25rem 0.75rem;
    font-weight: var(--font-medium);
}

.work__item:hover {
    border-bottom: 3px solid #505e5d;
    transition: 0.3s;
}

.work__container {
    grid-template-columns: repeat(3, max-content);
    gap: 3rem;
    justify-content: center;
}

/* .work__card {
    background-color: var(--container-color);
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 1.25rem;
    border-radius: 1rem;
} */

.work__img {
    width: 400px;
    border-radius: 1rem;
    margin-bottom: var(--mb-1);
}

.work__title {
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
    margin-bottom: var(--mb-0-5);
}

.work__button {
    color: var(--text-color);
    font-size: var(--small-font-size);
    display: flex;
    align-items: center;
    column-gap: 0.25rem;
}

.work__button-icon {
    font-size: 1rem;
    transition: 0.4s;
}

.work__button:hover .work__button-icon {
    transform: translateX(0.25rem);
}

/* Active work */
.active-work {
    border-bottom: 3px solid #505e5d;
}

.wrapper {
    display: flex;
    width: 100vw;
    overflow-x: auto;
    scroll-snap-type: x;
    cursor: grab;
    scroll-behavior: smooth;
    scrollbar-width: none;
}

.wrapper::-webkit-scrollbar {
    /* for chrome */
    display: none;
}

.slider {
    scroll-snap-align: start;
}

.image {
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
    .work__container {
        gap: 1.25rem;
    }

    .work__card {
        padding: 1rem;
    }

    .work__img {
        margin-bottom: 0.75rem;
    }

    .work__title {
        margin-bottom: 0.25rem;
    }
}

/* For medium devices */
@media screen and (max-width: 768px) {
    .work__container {
        grid-template-columns: max-content;
    }
}

@media screen and (max-width: 576px) {
    .slider {
        overflow-y: hidden;
        min-width: 100%;
        height: 115px;
        display: flex;
        justify-content: center;
        /* align-items: center; */
        scroll-snap-align: start;
        object-fit: cover;
    }

    .work__container {
        grid-template-columns: 1fr;
    }

    .work__img {
        width: 100%;
    }
}

/* For small devices */
@media screen and (max-width: 350px) {
    .work__item {
        font-size: var(--small-font-size);
    }

    .work__filters {
        column-gap: 0.25rem;
    }
}
